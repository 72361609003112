@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Teko:wght@300..700&display=swap');
/* @import url(./assets/fonts/Rubik-Black.ttf);
@import url(./assets/fonts/Rubik-Bold.ttf);
@import url(./assets/fonts/Rubik-ExtraBold.ttf);
@import url(./assets/fonts/Rubik-Light.ttf);
@import url(./assets/fonts/Rubik-SemiBold.ttf);
@import url(./assets/fonts/Rubik-Regular.ttf); */

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: 100%;
    line-height: inherit
}
:root {
    --thm-font: "Teko", sans-serif;
    --thm-b-font: "Rubik", sans-serif;
    --thm-base: #ffaa17;
    --thm-base-rgb: 255, 170, 23;
    --thm-black: #222429;
    --thm-text: #686a6f
}
html{
    overflow-x: hidden;
}
::selection {
	background: #faab25;
	color: #fff;
	text-shadow: none
}

::-webkit-scrollbar {
	width: 7px;
	background-color: #f5f5f5
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
	display: none
}

::-webkit-scrollbar-track-piece {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: #dedede
}

::-webkit-scrollbar-thumb:vertical {
	border-radius: 0;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-image: linear-gradient(#ffaa17, #ffaa17)
}

body {
    font-size: 16px;
    font-family: var(--thm-b-font);
    color: var(--thm-text);
    line-height: 2.125em;
    font-weight: 400;
    background: #f7f7f7;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    top: 0 !important;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: var(--thm-base)
}

.theme_color {
    color: var(--thm-base)
}

a:hover,
a:focus,
a:visited,
a:active,
button:active {
    text-decoration: none;
    outline: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: 400;
    /* margin: 0 0 20px; */
    background: 0 0;
    line-height: 1.25em;
    font-family: var(--thm-font);
    color: var(--thm-black)
}


section {
    position: relative;
}

select,
button {
    font-family: var(--thm-font)
}

textarea {
    overflow: hidden;
    resize: none
}

button {
    outline: none !important;
    cursor: pointer
}

p {
    line-height: 2.125em;
    margin: 0 0 20px;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
}

.text {
    line-height: 2.125em;
    margin: 0
}

::-webkit-input-placeholder {
    color: inherit
}

::-moz-input-placeholder {
    color: inherit
}

::-ms-input-placeholder {
    color: inherit
}
.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0
}

figure {
    margin-bottom: 0
}

.theme-btn {
    display: inline-block;
    transition: all .3s ease
}

.theme-btn i {
    position: relative;
    display: inline-block;
    font-size: 14px;
    margin-left: 3px
}

.centered {
    text-align: center !important
}

.btn-style-one {
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05em;
    background: #ffaa17;
    color: var(--thm-black);
    border-radius: 5px;
    overflow: hidden;
    font-family: var(--thm-font);
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    border: 1px solid #ffaa17
}

.btn-style-one .btn-title {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: .1em;
    padding: 7px 25px;
    z-index: 1
}

.btn-style-one:hover {
    background: var(--thm-base);
    color: #000
}

.btn-style-one .btn-curve {
    position: absolute;
    right: -15px;
    top: 0;
    width: 26px;
    height: 100%;
    background: #fff;
    opacity: .2;
    z-index: 0;
    transform: skewX(-22deg);
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.btn-style-one:hover .btn-curve {
    opacity: 1;
    right: 0;
    width: 100%;
    transform: skewX(0deg)
}

.btn-style-two {
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05em;
    background: #222429;
    color: #fff;
    border-radius: 5px;
    overflow: hidden;
    font-family: var(--thm-font);
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    border: 1px solid #222429
}

.btn-style-two .btn-title {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    z-index: 1;
    transition: .5s;
    letter-spacing: .1em;
    padding: 7px 25px
}

.btn-style-two:hover {
    color: var(--thm-black)
}

.btn-style-two .btn-curve {
    position: absolute;
    right: -15px;
    top: 0;
    width: 26px;
    height: 100%;
    background: #fff;
    opacity: .2;
    z-index: 0;
    transform: skewX(-22deg);
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease
}

.btn-style-two:hover .btn-curve {
    opacity: 1;
    right: 0;
    width: 100%;
    transform: skewX(0deg)
}

.btn-style-three {
    background-color: var(--thm-base);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 22.5px 40px;
    font-family: var(--thm-font);
    display: inline-flex;
    align-items: center;
    line-height: 1
}

.btn-style-three:hover {
    background-color: var(--thm-black);
    color: #fff
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fff;
    background-color: #1c1e22
}

.preloader__gradient {
    background: #432ff6;
    background: linear-gradient(100deg, rgba(67, 47, 246, 1) 0%, rgba(241, 116, 227, 1) 100%)
}

.preloader .icon {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(./assets/images/icons/preloader.png);
    transition: all 200ms ease;
    -webkit-animation-name: rotateMe;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateMe;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateMe;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: rotateMe;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.preloader__gradient .icon {
    background-image: url(./assets/images/update-26-02-2021/preloader-white.png)
}

.page-loaded .preloader .icon {
    opacity: 0
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto
}

.dropdown-toggle::after {
    display: none
}

.fa {
    line-height: inherit
}

.scroll-to-top {
    position: fixed;
    right: 35px;
    bottom: 12%;
    width: 44px;
    height: 44px;
    color: var(--thm-black);
    font-size: 14px;
    line-height: 44px;
    border-radius: 50%;
    text-align: center;
    z-index: 100;
    cursor: pointer;
    background: var(--thm-base);
    display: none;
    transition: all 300ms ease
}

.scroll-to-top:hover {
    background: var(--thm-black);
    color: #fff
}

.main-header {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    z-index: 9999;
    background: 0 0
}

.main-header .header-upper {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    background: 0 0;
    z-index: 1;
    transition: all 300ms ease;
    padding: 30px 0 0;
}

.main-header .header-upper .inner-container {
    position: relative;
    display: block;
    padding: 0;
}

.main-header .header-upper .logo-box {
    position: relative;
    float: left;
    margin-right: 50px;
    z-index: 7;
}

.main-header .header-upper .logo-box .logo {
    position: relative;
    display: block;
}

.main-header .other-links {
    display: flex;
    align-items: center;
    position: relative;
    float: right;
    z-index: 7;
}

.main-header .other-links .link-box {
    position: relative;
    float: left;
}

header .header-upper .inner-container {
    padding: 0;
}

.link-box ul li {
    display: inline-block;
}

.main-header .other-links .link-box .sub-text {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    font-family: var(--thm-font);
}

.main-header .other-links .link-box .number {
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--thm-font);
}

.main-header .other-links .call-us {
    position: relative;
}

.main-header .other-links .link-box .link {
    position: relative;
    display: block;
    padding-top: 4px;
    text-transform: uppercase;
    letter-spacing: .05em;
    padding-left: 60px;
    color: #fff;
}

.main-header .other-links .link-box .link .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 44px;
    height: 42px;
    background: url(./assets/images/icons/chat-icon.png) left center no-repeat;
}

.main-header .other-links .link-box .link .icon.call {
    background: url(./assets/images/icons/call-icon.png) left center no-repeat;
}

.color-bg .header-upper {
    background: var(--thm-black);
    -ms-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .08);
    -o-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .08);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .08);
    padding: 15px 0 5px;
}
.fixed-header .header-upper .logo-box {
    position: relative;
    float: left;
    padding: 15px 0 0;
    margin-right: 50px;
    z-index: 7;
}

/* banner */

.banner-section {
    background: 0 0;
}

.banner-section {
    display: block;
    color: #fff;
    overflow: hidden;
}

.banner-section {
    background-color: #222429;
}

.banner-section .bg-image {
    opacity: .4;
}

.banner-section::before {
    content: '';
    position: absolute;
    left: -19%;
    bottom: -34%;
    width: 600px;
    height: 600px;
    background: rgb(255 170 23 / 30%);
    border-radius: 50%;
}

.banner-section .bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center top;
    background-size: cover;
}

.banner-section .content-box {
    padding: 150px 0 130px;
}


.banner-section .content-box h1 {
    display: block;
    line-height: .85em;
    color: #fff;
    font-size: 75px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    margin: 0;
    transform: translateY(80px);
}

.banner-section .content-box .text {
    position: relative;
    color: #fff;
    margin: 5px 0 0;
    transform: translateY(80px);
    font-weight: 500;
}

.banner-section .content-box {
    max-width: 550px;
}

.banner-section .content-box {
    position: relative;
    display: block;
    max-width: 800px;
    z-index: 1;
}

.trust-lst {
    margin-top: 17%;
    display: flex;
}

.trust-lst>li {
    display: inline-block;
    border-right: 1px solid #ffaa17;
    padding: 0 22px;
}

.trust-lst>li:first-child {
    padding-left: 0;
}
.trust-lst>li:last-child{
    border-right: 0;
}
.get_btn {
    margin-top: 6%;
}

.cc-lst {
    display: flex;
    gap: 0.3rem;
}

.cc-lst>li {
    display: inline-block;
}

.content-wrp {
    margin: 100px 0 0;
    background: rgb(34 36 41 / 70%);
    padding: 20px 12px 10px;
    border-radius: 30px 30px 30px 30px;
    position: relative;
    border: 7px solid #ffaa17;
    width: 80%;
    float: inline-end;
}

.cont-bx h3 {
    color: #fba91e;
    font-size: 40px;
    margin-bottom: 0;
    line-height: 30px;
}

.cont-bx h1 {
    font-size: 27px;
    color: #fff;
    margin-bottom: 0;
}

.l-field {
    margin-bottom: -1px;
}

.l-field label {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 100;
}

.l-field input,
.l-field textarea {
    width: 100%;
    padding: 2px 12px;
    font-family: inherit;
    font-weight: 400;
    border-radius: 7px;
}

.l-field textarea {
    height: 130px;
}

li.marg-l .call-us a.link {
    padding: 0 30px 0;
}

.ma-pp {
    padding: 0;
}

/* facts-section */

.facts-section {
    padding: 30px 0;
    background: #191919;
    color: #fff;
    z-index: 2;
}

.fact-counter .content-box .count-outer {
    font-weight: 400;
    font-size: 80px;
    line-height: 1em;
    letter-spacing: 0;
    display: block;
    font-family: var(--thm-font);
}

.fact-counter .content-box .counter-title {
    position: relative;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    font-family: var(--thm-font);
    text-transform: uppercase;
}

.fact-counter .content-box {
    position: relative;
    text-align: center;
}

.fact-counter .content-box:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    bottom: 7px;
    border-right: 1px solid rgba(255, 255, 255, .15);
}

.clearfix::after {
    display: none;
    clear: both;
    content: "";
}

.fact-counter .border0::after  {
    display: none;
}
.facts-section .c-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.cta_cover {
    background: var(--thm-base);
    background-image: url(./assets/images/background/cta-2-bg-1-1.png);
    padding: 50px 0;
}

.cta_cover h2 {
    font-size: 70px;
    font-weight: 400;
    margin: 0;
    color: var(--thm-black);
    text-transform: uppercase;
    line-height: 0.9;
}

.inner p {
    color: #000;
    font-weight: 500;
}

.maps-section {
    background-color: #fff;
    /* background-image: url(../bg2.png); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px 0;
}

.maps-section .section-head h2 {
    font-size: 45px;
    text-align: center;
    line-height: 1.2;
    font-weight: 800;
}

.icon-bx-wraper {
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .1);
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
    transition: all 1s;
    text-align: center;
    cursor: pointer;
}
.icon-content p{margin-bottom: 0;}

video#myVideo {
    width: 100%;
}

.dlab-title {
    font-size: 40px;
}

.icon-bx-sm img {
    height: 60px;
}



.icon-bx-wraper:hover {
    background-color: #000;
    color: #fff;
}

.icon-bx-wraper:hover .icon-content>h4 {
    color: #fff;
}

.sec-title h2 {
    font-size: 55px;
    line-height: .9em;
    text-transform: uppercase;
    color: var(--thm-black);
    font-weight: 400;
    margin-bottom: 0;
}

.services-section {
    background: #222429;
    padding: 100px 0;
}
.services-section-two .services {
    position: relative;
    z-index: 2;
}
.sec-title h2 {
    color: #fff;
}

.sec-title h2 .dot {
    color: var(--thm-base);
    padding-left: 7px;
}

.service-block-two {
    position: relative;
    margin-bottom: 30px;
}
.service-block-two .inner-box {
    position: relative;
    display: block;
    padding: 50px 30px;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
    transition: all 500ms ease;
    overflow: hidden;
    height: auto;
    text-align: center;
    cursor: pointer;
}

.service-block-two .inner-box .icon-box {
    position: relative;
    display: block;
    font-size: 64px;
    line-height: 1em;
    margin-bottom: 20px;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.service-block-two h5 a{
    color: var(--thm-black);
}
.service-block-two .inner-box h5 {
    font-size: 30px;
    text-transform: uppercase;
    color: var(--thm-black);
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.1em;
    margin: 0;
}

.service-block-two .text {
    position: relative;
    display: block;
    color: var(--thm-text);
    padding: 30px 0 0;
    z-index: 1;
    line-height: 25px;
    font-weight: 400;
}

.inner-box h5:hover {
    text-decoration: underline;
    transition: all ease-in-out 3s;
    color: #fff;
}
.service-block-two h5 a:hover{
    color: #fff;
}
.service-block-two .inner-box:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .04);
    transition: all 500ms ease;
}

.service-block-two .inner-box:hover:before {
    background: #ffaa17;
    bottom: -10px;
}
.icon-box img {
    width: 60px;
}
.service-block-two .inner-box .bottom-curve {
    position: absolute;
    right: -70px;
    bottom: -10px;
    width: 120px;
    height: 120px;
    background: #fff;
    opacity: .1;
    z-index: 0;
    transform: skewX(0deg) scaleX(0);
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.service-block-two .inner-box:hover .bottom-curve {
    transform: skewX(-45deg) scaleX(1);
}

.service-block-two .inner-box:hover .icon-box img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.service-block-two .inner-box:hover h5,
.service-block-two .inner-box:hover h5 a {
    color: #fff;
}

.services-section .services {
    padding-top: 3rem;
}

.testimonials-four {
    padding: 100px 0;
}

.testimonials-four-card {
    border-style: solid;
    border-width: 1px;
    border-color: #e9ebee;
    border-radius: 8px;
    background-color: #fff;
    padding: 45px;
    padding-bottom: 63px;
    padding-top: 30px;
    position: relative;
    transition: all 500ms ease;
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0;
}

.testimonials-four-card .image {
    display: inline-flex;
    justify-content: center;
    padding: 10px;
    border: 1px solid #e9ebee;
    border-radius: 8px;
    transition: all 500ms ease;
    margin-bottom: 20px;
    width: 100px;
}

.testimonials-four-card:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .04);
}

.testimonials-four-card__content .name {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 24px;
    line-height: 1;
    color: var(--thm-black);
    font-weight: 400;
    font-family: var(--thm-font);
    margin-bottom: 20px;
}

.testimonials-four-card__content p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #686a6f;
    font-family: var(--thm-b-font);
    margin-bottom: 15px;
    height: 100px;
}

.testimonials-four-card .image img {
    border-radius: 8px;
}

.advan {
    text-align: center;
    padding-top: 5rem;
}

.testimonials-four .sec-title h2 {
    position: relative;
    display: block;
    font-size: 55px;
    line-height: .9em;
    text-transform: uppercase;
    color: var(--thm-black);
    font-weight: 400;
    margin-bottom: 0;
}

.clr {
    color: #ffaa17;
}

.call-to-section {
    position: relative;
    padding: 0;
    z-index: 1;
}

.pad {
    padding: 0;
}

.call-to-section .inner {
    position: relative;
    display: block;
    padding: 95px 95px 68px;
    background: var(--thm-base);
    color: var(--thm-black);
    overflow: hidden;
    text-align: center;
}

.call-to-section h2 {
    position: relative;
    display: block;
    font-size: 60px;
    line-height: .85em;
    text-transform: uppercase;
    color: var(--thm-black);
    font-weight: 500;
    margin: 0 0 15px;
    z-index: 3;
}

.clr2 {
    color: #fff;
}

section.call-to-section p {
    font-size: 20px;
}

ul.c-btn {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.call-to-section .shape-1 {
    position: absolute;
    right: 0;
    top: 0;
    width: 340px;
    max-width: 100%;
    height: 100%;
    background: url(./assets/images/icons/call-to-shape-1.png) right top no-repeat;
    z-index: 1
}

.call-to-section .shape-2 {
    position: absolute;
    right: 170px;
    top: 0;
    width: 410px;
    height: 54px;
    background: url(./assets/images/icons/call-to-shape-2.png) right top no-repeat;
    z-index: 0
}

.history-timeline .sec-title {
    text-align: center;
}

.history-timeline {
    padding: 80px 0;
}

.faqs-section {
    position: relative;
    padding: 80px 0 100px;
    overflow: hidden;
    background: #222429;
}

.sec-title h2 {
    position: relative;
    display: block;
    font-size: 55px;
    line-height: .9em;
    text-transform: uppercase;
    color: var(--thm-black);
    font-weight: 400;
    margin-bottom: 0;
}

.sec-title .lower-text {
    position: relative;
    display: block;
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    color: var(--thm-text);
}

.clr {
    color: #ffaa17;
}

.sec-title.centered {
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.faqs-section .sec-title h2 {
    color: #ffff;
}

.services-section .sec-title h2 {
    position: relative;
    display: block;
    font-size: 55px;
    line-height: .9em;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

.sec-title h2 .dot {
    color: var(--thm-base);
    padding-left: 7px;
}

.accordion-box .block .acc-btn {
    position: relative;
    line-height: 32px;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 20px 40px;
    padding-right: 60px;
    color: var(--thm-black);
    transition: all 500ms ease;
    font-family: var(--thm-font);
}

.accordion-box .block .content .text {
    display: block;
    position: relative;
    top: 0;
    display: block;
}

.accordion-box .block {
    position: relative;
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    margin-bottom: 16px;
    -ms-box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
    box-shadow: 0 0 40px 5px rgba(0, 0, 0, .03);
}

.accordion-box .block .content {
    position: relative;
    padding: 0 40px 30px;
}

.accordion-box .block .acc-btn:before {
    position: absolute;
    right: 35px;
    top: 7px;
    height: 30px;
    font-size: 30px;
    font-weight: 600;
    color: var(--thm-base);
    line-height: 30px;
    content: "_";
    font-family: flaticon;
    transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active:before {
    color: var(--thm-black);
    content: "+";
    font-size: 30px;
    top: 23px;
}
.accordion-box .block .acc-content {
    position: relative;
    display: none;
}
.accordion-box .block .acc-btn .count {
    color: var(--thm-base);
    padding-right: 3px;
}

.accordion-box .block.active-block {
    background-color: #fff;
    -ms-box-shadow: 0 0 40px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 0 40px 5px rgba(0, 0, 0, .05);
}

.testimonials-section {
    position: relative;
    padding: 80px 0;
    background: #fff url(./assets/images/background/pattern-2.png) left top repeat;
    overflow: hidden;
}
.testimonials-section .auto-container{
   max-width: 100%;
}
.testimonials-section .sec-title {
    margin-bottom: 40px;
    padding-left: 30rem;
}

.testi-block .inner {
    position: relative;
    display: block;
    padding: 30px;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .04);
    transition: all 500ms ease;
    height: 350px;
}

.testi-block .icon {
    position: absolute;
    right: 50px;
    font-weight: 700;
    font-size: 100px;
    line-height: 1em;
    color: var(--thm-base);
    transition: all 500ms ease;
}

.testi-block .info {
    position: relative;
    min-height: 70px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.testi-block .text {
    position: relative;
    display: block;
    color: var(--thm-text);
    transition: all 500ms ease;
    padding: 0;
    font-weight: 500;
}

.testi-block .info .name {
    font-size: 24px;
    line-height: 1.2em;
    color: var(--thm-black);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-family: var(--thm-font);
}
.testimonials-section .owl-theme .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 6px;
}
.testimonials-section .owl-theme .owl-dots .owl-dot span {
    position: relative;
    display: block;
    width: 10px;
    height: 10px;
    background: #d3d3d4;
    border: 2px solid #d3d3d4;
    border-radius: 50%;
}
.testimonials-section .owl-theme .owl-dots .owl-dot.active span {
    background: #fff;
    border-color: var(--thm-base);
}
.testimonials-section .owl-dots {
    position: absolute;
    top: -91px;
    right: 25rem;
}
.testimonials-section .testi-block:hover .inner {
    background-color: var(--thm-base);
}
.testimonials-section .testi-block:hover :is(.text,.icon) {
    color: var(--thm-black);
}

.get-quote-two {
    position: relative;
    padding: 80px 0;
    background-color: var(--thm-black);
    background-image: url(./assets/images/background/contact-bg-1-1.png);
    background-size: cover;
    color: #999b9f;
}

.get-quote-two .sec-title h2 {
    position: relative;
    display: block;
    font-size: 55px;
    line-height: .9em;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

.get-quote-two .form-box form .form-group {
    padding: 0 10px;
    margin-bottom: 29px;
}

.default-form .form-group :is(input, textarea) {
    position: relative;
    display: block;
    height: 70px;
    width: 100%;
    font-size: 14px;
    color: #686a6f;
    line-height: 40px;
    font-weight: 400;
    padding: 14px 30px;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    border-radius: 7px;
    font-family: var(--thm-b-font);
    transition: all 300ms ease;
}

.default-form .form-group textarea {
    height: 220px;
    resize: none;
}

.iti {
    display: unset;
}

.get-quote-two .sec-title h2 {
    color: #fff;
    margin-bottom: 35px;
}

.fluid-section .column .inner {
    position: relative;
    display: block;
    text-align: center;
    min-height: 100%;
    padding: 120px 20px;
    background: #1c1e22;
}

.fluid-section .image-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    opacity: .1;
}

.fluid-section .column {
    padding: 0;
}

.fluid-section h3 {
    position: relative;
    display: block;
    font-size: 60px;
    line-height: 1.1em;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    margin: 0;
    z-index: 3;
}

.fluid-section .column:nth-child(2) h3 {
    color: var(--thm-black);
}

.fluid-section .content-box {
    position: relative;
    max-width: 620px;
    margin: 0 auto;
}

.fluid-section .column:nth-child(2) .inner {
    background: var(--thm-base);
}

.fluid-section .link-box {
    position: relative;
    padding-top: 25px;
    z-index: 3;
}


.testimonials-four .owl-nav .owl-prev {
    position: absolute;
    top: 200px;
    left: -35px;
}

.testimonials-four .owl-nav .owl-prev span {
    font-size: 45px;
    font-weight: 500;
    color: #ffaa17;
}

.testimonials-four .owl-nav .owl-next {
    position: absolute;
    top: 200px;
    right: -35px;
}

.testimonials-four .owl-nav .owl-next span {
    font-size: 45px;
    font-weight: 500;
    color: #ffaa17;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #ffffff00;
}

.testimonials-four .owl-theme .owl-dots span {
    height: 6px !important;
    width: 20px !important;
    background: #222429 !important;
    cursor: pointer;
    font-size: 0;
    padding: 0;
    -webkit-transition: all .4s ease-In-out;
    -moz-transition: all .4s ease-In-out;
    -o-transition: all .4s ease-In-out;
    transition: all .4s ease-In-out;
    box-sizing: border-box;
    border-radius: 0;
}

.testimonials-four .owl-theme .owl-dots .owl-dot.active span {
    background: #fba91e !important;
}
.main-footer {
    position: relative;
    color: #999b9f;
    background: var(--thm-black) url(./assets/images/background/footer-bg.png) center top no-repeat;
}

.main-footer .logo-widget .text {
    position: relative;
    display: block;
    margin: 0 0 30px;
    font-weight: 500;
}
.main-footer .column {
    position: relative;
}
.main-footer .widgets-section {
    padding: 30px 0;
}

.main-footer .social-links li a {
    position: relative;
    display: block;
    color: #999b9f;
    font-size: 16px;
    width: 44px;
    height: 44px;
    line-height: 44px;
    background: #1c1e22;
    text-align: center;
    border-radius: 50%;
    transition: all 300ms ease;
}

.main-footer .social-links li {
    position: relative;
    margin-right: 8px;
    display: inline-block;
}

.main-footer .social-links li a:hover {
    color: #fff;
    background: var(--thm-base);
}

.main-footer h6 {
    position: relative;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .03em;
    margin-bottom: 30px;
}
ul.pay-lst {
    display: flex;
    align-items: center;
    justify-content: end;
}

.main-footer .info-widget ul li i {
    padding-right: 5px;
    color: var(--thm-base);
}
.main-footer .info-widget ul li {
    position: relative;
    display: block;
    line-height: 32px;
    margin-bottom: 5px;
}

.main-footer .info-widget ul li a {
    color: #999b9f;
    line-height: 28px;
    font-weight: 500;
}
.main-footer .footer-bottom .copyright {
    position: relative;
    line-height: 1.5em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}
.main-footer .footer-bottom .inner {
    position: relative;
    display: block;
    padding: 20px 0;
    line-height: 30px;
    color: #999b9f;
    border-top: 1px solid rgba(255, 255, 255, .1);
}

/* auto-popup */

.popupMain {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 550px;
    height: 550px;
    padding: 0;
    border-radius: 15px;
    margin: auto;
    z-index: 999999999999999;
    display: flex;
    align-items: center;
    display: none;
    box-shadow: 0 2px 18px 0 #ffaa17;
    background: #fff;
    overflow: hidden;
}
img.wki {
	position: absolute;
	z-index: -1;
	opacity: .4;
	left: -20%;
	top: -22%;
	-webkit-animation: rotation 60s infinite linear;
	animation: rotation 60s infinite linear
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(359deg)
	}
}

@keyframes rotation {
	from {
		-webkit-transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(359deg)
	}
}

.popupMain .closePop {
    position: absolute;
    right: -4px;
    top: 0;
    background: #ffaa17;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 600;
}
.pp-wrapp {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.popform-holder {
    width: 80%;
    margin: auto;
}
.popTxt {
    margin-bottom: 15px;
}
.popTxt h4 {
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 1;
}
.pop-filed {
    margin-bottom: 15px;
}
.pop-filed input, .pop-filed select, .pop-filed textarea {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    background: #fff;
}
.pop-filed input, .pop-filed select, .pop-filed textarea {
    width: 100%;
    height: 45px;
    border: 1px solid #222429;
    padding: 0 18px;
    font-family: poppins, sans-serif;
    color: #222429;
    background: #fff;
    border-radius: 6px;
}
.pop-btn .btn-form {
    padding: 10px 50px;
    font-size: 23px;
    background: #ffaa17;
    border-radius: 6px;
}
.btn-form:hover {
    color: #fff;
    transition: all .9s ease;
    transform: scale(.9);
}


@media (max-width: 1600px) {
  .testimonials-section .owl-dots{
      right: 10rem;
  }
  .testimonials-section .sec-title{
      padding-left: 20rem;
  }
}
@media (max-width: 1399.98px) {
  .testimonials-section .sec-title {
      padding-left: 6rem;
  }
}


@media (max-width: 1200px) {
  .testimonials-section .owl-dots {
      right: 2rem;
  }
}


@media (max-width: 991.98px) {
  .content-wrp{
      width: 100%;
  }
  :is(.testimonials-section, .get-quote-two, .maps-section, .faqs-section) {
      padding: 40px 0;
  }

  .fluid-section .column .inner {
      padding: 20px 0;
  }

  .fluid-section h3 {
      font-size: 43px;
  }

  .get-quote-two .sec-title h2 {
      font-size: 50px;
  }

  .testimonials-section .owl-dots {
      right: 0;
  }

  .testimonials-section .sec-title {
      padding: 0;
  }

  .call-to-section .inner {
      padding: 70px 20px 60px
  }

  .call-to-section h2 {
      font-size: 48px;
  }
}


@media (max-width: 767.98px) {
  .ppopupMain, .popupMain, .pppopupMain {
      width: 94%;
  }  
  .testimonials-section .owl-dots{
      top: -80px;
  }
  .main-header .other-links {
      display: none;
  }

  .banner-section .content-box {
      padding: 30px 0;
  }
 
  .content-wrp {
      width: 90%;
      float: unset;
      margin: 25px auto 30px;
      border-radius: 0;
  }
  .cont-bx h3 {
      font-size: 28px;
      margin-bottom: 0;
      text-align: left;
  }
  .banner-section::before{
      display: none;
  }
  .banner-section .content-box h1{
      font-size: 46px;
      text-align: center;
  }
  .banner-section .content-box .text{
      text-align: center;
  }
  .content-box .get_btn{
    display: none;
  }
  .c-logo  {
      display: block !important;
      width: 34%;
      margin-right: 0;
      vertical-align: middle;
      margin: auto;
      margin-bottom: 20px;
  }
  .c-logo li img{
      padding: 10px 0;
  }
  .fact-counter .content-box:after{
      display: none;
  }
  .cta_cover h2{
      font-size: 30px;
      text-align: center;
      padding-bottom: 1.5rem;
  }
  .services-section .sec-title h2{
      font-size: 36px;
      text-align: center;
  }
  .testimonials-four .sec-title h2{
      font-size: 36px;
  }
  .sec-title h2{
      font-size: 36px;
  }
  .get-quote-two .sec-title h2 {
      font-size: 36px;
  }
  .fluid-section h3, .project-single.style-two h5 {
      font-size: 32px;
  }
}

@media (max-width: 575.98px) {
  .main-footer h6{
      margin-bottom: 0;
  }
  .call-to-section h2{
      font-size: 32px;
  }
  .testimonials-four-card{
      width: 100%;
      height: auto;
  }
  .main-footer .widgets-section {
      padding: 30px 0;
      text-align: center;
  }
  .main-footer .footer-bottom .inner{
      padding: 15px 0;
  }
  .main-footer .footer-bottom .copyright{
      text-align: center;
      padding-bottom: 10px;
  }
  ul.pay-lst{
      justify-content: center;
  }
  .testi-block .inner{
      padding: 30px;
      height: 60vh;
  }
}

@media (max-width: 375px) {
  .testimonials-section .owl-dots {
      top: -40px;
  }
  .fluid-section .content-box{
      padding: 0 28px;
  }
}


.banner-section .bg-image {
  background-image: url(./assets/images/main-slider/baner-po.webp);
}

.theme-btn {
  width: 100%;
}

.fluid-section .innerone .image-layer {
  background-image: url(./assets/images/background/image-4.jpg);
}

.fluid-section .innersecond .image-layer {
  background-image: url(./assets/images/background/image-5.jpg);
}

.copyright a {
  color: #ffaa17;
}

.modal-content {
  border: none;
  background: transparent;
}